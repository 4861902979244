import { IArrowBoardConfiguration } from "../arrow-board-configuration";
import { AnalysisType } from "../measuring-point";
import { IMeasuringPointStatusCreator } from "../measuring-point-status";
import { IProjectNavigator } from "../project";
import { IScenarioNavigator } from "../scenario";
import { IVehicleAnalysisTypeConfiguration } from "../vehicle-analysis-type-configuration";
import { IVmsModeConfiguration } from "../vms";
import { IAlertSummary } from "./alert-summary";

export interface IMeasuringPointStatusSummary {
    measuringPointId: number;
    analysisType: AnalysisType;
    code: string;
    description: string;
    from: string;
    to: string;
    heading?: number;
    lastDeviceLinkValidFrom?: Date;
    lastDeviceLinkValidUntil?: Date;
    currentStatus: IMeasuringPointStatusCreator;
    projects: IProjectNavigator[];
    linkedScenarios: IScenarioNavigator[];
    alerts: IAlertSummary[];
    mapIconStateId: number;

    vehicleConfiguration: IVehicleAnalysisTypeConfiguration;
    arrowBoardConfiguration: IArrowBoardConfiguration;
    vmsModeConfiguration: IVmsModeConfiguration;
}
